@import '../../styles/includes';

.RawHtml {
    color: $colorGray90;

    h2,
    h3,
    h4,
    h5,
    h6 {
        word-break: break-word;
        font-family: $fontLight;
        margin-bottom: 8px;
    }

    h1 {
        @extend %h1;
        font-family: $fontLight;
        margin-bottom: $spacingL;
    }

    h2 {
        font-family: $fontLight;
        font-size: 2.8rem;
        line-height: 1.32;
        color: inherit;

        @include media(m) {
            font-size: 3.2rem;
        }
    }

    h3 {
        font-family: $fontLight;
        font-size: 2rem;
        line-height: 1.32;
        color: inherit;

        @include media(m) {
            font-size: 2.2rem;
        }
    }

    h4 {
        font-family: $fontLight;
        font-size: 2rem;
        line-height: 1.32;
        color: inherit;

        @include media(m) {
            font-size: 2.2rem;
        }
    }

    h5 {
        font-family: $fontLight;
        font-size: 1.8rem;
        line-height: 1.32;
        color: inherit;

        @include media(m) {
            font-size: 2.0rem;
        }
    }

    p {
        @extend %p;
        margin-bottom: calc($spacingM + $spacingS);
    }

    a {
        border-bottom: 1px;
        border-style: solid;
        border-color: inherit;

        &[href] {
            &[href$='.pdf'],
            &[href$='.doc'],
            &[href$='.docx'],
            &[href$='.ppt'],
            &[href$='.pptx'],
            &[href$='.xls'],
            &[href$='.xlsx'],
            &[href$='.odt'],
            &[href$='.eps'],
            &[href$='.zip'],
            &[href$='.ngb'],
            &[href$='.ncb'],
            &[href$='.nc2b'],
            &[href$='.nsb'],
            &[href$='.neb'],
            &[href$='.ne2b'],
            &[href$='.ne3b'],
            &[href$='.ne4b'],
            &[href$='.ne5b'],
            &[href$='.ne6b'],
            &[href$='.nlab'],
            &[href$='.npb'],
            &[href$='.np2b'],
            &[href$='.np3b'],
            &[href$='.np4b'],
            &[href$='.np5b'],
            &[href$='.nwb'],
            &[href$='.nw2b'],
            &[href$='.ns2b'],
            &[href$='.ns3b'],
            &[href$='.ns4b'],
            &[href$='.dmg'] {
                position: relative;

                &::after {
                    content: '';
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    top: auto;
                    bottom: 0;
                    right: -30px;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-image: url('/img/download.svg');
                    background-size: contain;
                }
            }
        }
    }

    strong,
    b {
        font-family: $fontBold;
    }

    em {
        font-style: italic;
    }

    i {
        font-style: italic;
    }

    ul,
    ol {
        margin-bottom: 24px;
        margin-left: 12px;
        font-family: $fontLight;

        @include media(m) {
            margin-bottom: 36px;
        }
    }

    ol {
        list-style-type: decimal;
        padding-left: 16px;

        @include media(m) {
            padding-left: 20px;
        }
    }

    ul {
        padding-left: 16px;

        @include media(m) {
            padding-left: 18px;
        }
    }

    li {
        line-height: 1.4;
        margin-bottom: 14px;

        &:last-child {
            margin-bottom: 0;
        }

        @include media(m) {
            font-size: 1.6rem;
            margin-bottom: 18px;
        }
    }

    ul>li {
        padding-left: 8px;
        list-style-type: disclosure-closed;

        @include media(m) {
            padding-left: 12px;
        }

        &::marker {
            color: $colorRed50;
            flex-shrink: 0;
        }
    }

    ol>li {
        padding-left: 8px;

        @include media(m) {
            padding-left: 10px;
        }

        &::marker {
            color: $colorRed50;
            flex-shrink: 0;
        }
    }

    a {
        &:hover {
            color: $colorRed50;
        }
    }

    figcaption {
        font-family: $fontRegular;
        font-size: 1.2rem;
        line-height: (20 / 12);
        margin-bottom: 20px;
        margin-top: 16px;

        @include media(m) {
            margin-bottom: 40px;
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}