@import '../../styles/includes';

.Accordion {
    $root: &;
    @extend %container;

    &__Container {
        border-bottom: 1px solid $colorRed50;
    }

    &__Wrapper {
        width: 100%;
        display: flex;
        position: relative;
        flex-wrap: wrap;
        padding: 0;
        text-align: left;
        border: none;
        background-color: white;
        padding: 32px 0;

        @include media(m) {
            flex-wrap: nowrap;
            padding: 48px 0;
        }
    }

    &__Title {
        font-size: 24px;
        line-height: 1.25;
        font-family: $fontLight;
        width: 80%;
        margin: auto 0;

        @include media(m) {
            font-size: 32px;
            max-width: 33%;
            margin: 0;
            padding-right: $spacingL;
        }
    }

    &__Text {
        @extend %p;
        font-family: $fontLight;
        font-size: 18px;
        width: 100%;
        order: 3;
        margin-top: 24px;

        @include media(m) {
            font-size: 20px;
            margin-top: 6px;
            max-width: 50%;
            order: 2;
        }

        p {
            font-size: 18px !important;
            margin-bottom: 0 !important;

            @include media(m) {
                font-size: 20px !important;
            }
        }
    }

    &__Button {
        margin-left: auto;
        border: none;
        width: 48px;
        height: 48px;
        position: relative;
        background-color: $colorRed50;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        cursor: pointer;
        order: 2;

        @include media(m) {}
    }

    &__Icon {
        position: relative;
        width: 16px;
        height: 16px;
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            display: block;
            background-color: $colorWhite;
            position: absolute;
            top: 50%;
            left: 0;
            transition: 0.25s ease;
            width: 100%;
            height: 2px;
        }

        &.Accordion__Icon--Active {
            &:before {
                transform: rotate(-90deg);
                opacity: 0;
            }

            &:after {
                transform: translateY(-50%) rotate(0);
            }
        }

        &:before {
            transform: translateY(-50%);
        }

        &:after {
            transform: translateY(-50%) rotate(90deg);
        }
    }

    &__Content {
        display: flex;
        position: relative;
        transition: max-height 0.25s ease, margin 0.25s ease;
        will-change: max-height;
        overflow: hidden;
        max-height: 0;
        z-index: 2;

        #{$root}--AlwaysOpen & {
            transition: none;
            margin: 16px 0 16px 0;

            @include media(m) {
                margin: -16px 0 16px 0;
            }
        }

        &--Transition {
            will-change: margin;
            margin: 16px 0 32px 0;

            @include media(m) {
                margin: -16px 0 48px 0;
            }
        }
    }

    &__Images {
        max-width: 33%;
        width: 100%;
        padding-right: 80px;
        display: none;

        @include media(m) {
            display: block;
        }
    }

    &__Image {
        width: 100%;
        height: auto;
        margin-bottom: 16px;
    }

    &__ImageWrapper {
        display: none;

        @include media(m) {
            display: block;
        }
    }

    &__RichText {
        width: 100%;
        opacity: 0;
        transition: opacity 0.25s ease;
        will-change: opacity;

        #{$root}__Content--Transition &,
        #{$root}--AlwaysOpen & {
            opacity: 1;
        }

        @include media(m) {
            max-width: 50%;
        }
    }
}